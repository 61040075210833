/* eslint-disable eqeqeq */

import React from 'react'
import { graphql } from 'gatsby'

import Nav from '../atoms/Nav'
import Card from '../molecules/Card'
import HomepageHero from '../molecules/HomepageHero'
import SiteLayout from '../layouts/SiteLayout'
import './posts-homepage.sass'

const PostsHomePage = ({ data }) => {
  let page = data.wagtail.page
  let featured = !!page.children[0].featureType

  let cardData = (child, layout) => Object.assign({
    target: child.url,
    layout: layout == "articles" ? "vertical-list" : layout,
    key: child.url,
    className: "posts-homepage__card",
  },
  layout == "videos" ?
    ({
      image: child.image,
      dek: child.dek,
    }) : (layout == "images" ?
      {
        image: child.layout == "portrait" ? child.portrait : child.image,
        title: child.title,
      } : {
        title: child.title,
        author: child.author,
        action: child.featureType,
        image: child.featureType == "watch" ? child.image : null,
      }
    )
  )

  let cards = (page.layout == "featured") ? page.children.slice(1) : page.children

  return (
    <SiteLayout layout="top-nav" nav={<Nav/>} page={page} theme={featured ? "dark" : "light"}>
      <div className={`posts-homepage posts-homepage--${page.layout}`}>
        <HomepageHero data={page} className="posts-homepage__hero" layout={page.layout} />
        {(page.layout == "featured") &&
          <div className="posts-homepage__card-list posts-homepage__card-list--1">
            <Card {...cardData(page.children[0], page.layout)} />
          </div>}
        {(page.layout == "articles") &&
          <div className="posts-homepage__card-list posts-homepage__card-list--1">
            <div className="posts-homepage__2-col">
              {cards.map(child => {
                return (<Card {...cardData(child, "images")}/>)
              })}
            </div>
          </div>}
        
        <div className="posts-homepage__card-list posts-homepage__card-list--2">
          {cards.map(child => {
            return (<Card {...cardData(child, page.layout)} />)
          })}
        </div>
      </div>
    </SiteLayout>
  )
}

export const query = graphql`
  query($url: String) {
    wagtail {
      page (urlPath: $url) {
        ...PageMetadata
        ... on PostsHomePage {
          title1
          title2
          description
          backgroundText
          layout
          image {
            title
            rendition (width: 1200) {
              url
            }
          }
          imageAttribution
          children {
            url
            title
            ... on Article {
              author
              featureType
              layout
              image {
                title
                rendition (width: 600) {
                  url
                }
              }
              portrait: image {
                title
                rendition (fill: "600x800") {
                  url
                }
              }
            }
            ... on Video {
              dek
              featureType
              image {
                title
                rendition (fill: "800x450") {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
  fragment PostParent on PageInterface {
    parent {
      ... on PostsHomePage {
        title
        url
        titleAsParent
      }
    }
  }  
`

export default PostsHomePage
