/* eslint-disable eqeqeq */

import React from 'react'
import { graphql } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-amplitude-analytics'

import Nav from '../atoms/Nav'
import HomepageHero from '../molecules/HomepageHero'
import SiteLayout from '../layouts/SiteLayout'

import '@fontsource/damion/400.css'
import './acknowledgements.sass'


const AcknowledgementsPage = ({ data }) => {
  let page = data.wagtail.page
  return (
    <SiteLayout layout="top-nav" nav={<Nav/>} page={page}>
      <div className="acknowledgements">
        <HomepageHero
          data={{
            title1: page.title1, 
            title2: page.title2,
            description: page.description
          }}
          layout="acknowledgements"
          className="acknowledgements__hero"
        />
        <div className="acknowledgements__letter">
          {page.letter &&
            <div className="acknowledgements__letter-text" dangerouslySetInnerHTML={{ __html: page.letter }} />}
          {page.letterSignature &&
            <div className="acknowledgements__letter-signature" dangerouslySetInnerHTML={{ __html: page.letterSignature }} />}
          
          <div className="acknowledgements__cta">
            {page.buttonLink &&
              <OutboundLink className="acknowledgements__button" href={page.buttonLink}>
                {page.buttonText || "Support our work"}
              </OutboundLink>}

            {page.linkLink &&
              <OutboundLink className="acknowledgements__link" href={page.linkLink}>
                {page.linkText || "View our 2020 financials"}
              </OutboundLink>}
          </div>
        </div>
        {page.body &&
          <div className="acknowledgements__body" dangerouslySetInnerHTML={{ __html: page.body }} />}
      </div>
      {page.backgroundText && <p className="acknowledgements__background-text">{page.backgroundText}</p>}
    </SiteLayout>
  )
}

export const query = graphql`
  query($url: String) {
    wagtail {
      page (urlPath: $url) {
        ...PageMetadata
        ... on Acknowledgements {
          backgroundText
          title1
          title2
          description
          letter
          letterSignature
          body
          buttonLink
          buttonText
          linkLink
          linkText
        }
      }
    }
  }
`

export default AcknowledgementsPage
