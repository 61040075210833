/* eslint-disable eqeqeq */

import React from 'react'
import { graphql } from 'gatsby'

import NavPrevNext from '../molecules/NavPrevNext'
import Article from '../organisms/Article'
import SiteLayout from '../layouts/SiteLayout'

const ArticlePage = ({ data }) => {
  let page = data.wagtail.page
  let featured = !!page.featureType
  let siteLayout = "sidebar"

  let prev = page.previousSiblings ? page.previousSiblings[0] : null
  let next = page.nextSiblings ? page.nextSiblings[0] : null

  let nav = <NavPrevNext
    layout="article"
    parent={page.parent}
    prev={prev}
    next={next}
  />

  return (
    <SiteLayout layout={siteLayout} page={page} nav={nav} theme={featured ? "dark" : "light"}>
      <Article page={page} fullUrl={`${data.wagtail.site.hostname}${page.url}`}/>
    </SiteLayout>
  )
}

export const query = graphql`
  query($url: String) {
    wagtail {
      ...SiteMetadata
      page (urlPath: $url) {
        ...PageMetadata
        ... on Article {
          author
          layout
          dek
          body
          featureType
          imageAttribution
          image {
            title
            rendition (width: 8000) {
              url
            }
          }
          landscape: image {
            title
            rendition (fill: "6000x3500") {
              url
            }
          }
          portrait: image {
            title
            rendition (fill: "4000x4000") {
              url
            }
          }
        }
        ...PostParent
        previousSiblings {
          ...ArticleSiblingData
        }
        nextSiblings {
          ...ArticleSiblingData
        }
      }
    }
  }
  fragment ArticleSiblingData on PageInterface {
    title
    url
    ... on Video {
      featureType
      image {
        title
        rendition(width: 300) {
          url
        }
      }
    }
    ...on Article {
      featureType
      author
    }
  }
`

export default ArticlePage
