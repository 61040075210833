import React from 'react';
import PropTypes from 'prop-types'
import './homepage-hero.sass'

export const HomepageHero = ({ data, layout, ...props}) => (
  <div
    {...props}
    className={`
      homepage-hero
      homepage-hero--${layout}
      ${data.backgroundText && `homepage-hero--background-text`}
      ${props.className}
    `}
  >
    <h1 className="homepage-hero__title">
      {data.title1 && <span className="homepage-hero__title-1">{data.title1}</span>}
      {data.title2 && <span className="homepage-hero__title-2">{data.title2}</span>}
    </h1>
    {data.description && <div className="homepage-hero__description">{data.description}</div>}
    {data.backgroundText && <div className="homepage-hero__background">{data.backgroundText}</div>}
  </div>
)

HomepageHero.propTypes = {
  data: PropTypes.object,
  layout: PropTypes.string,
};

export default HomepageHero