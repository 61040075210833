/* eslint-disable eqeqeq */

import React from 'react'
import { graphql } from 'gatsby'

import NavPrevNext from '../molecules/NavPrevNext'
import Article from '../organisms/Article'
import SiteLayout from '../layouts/SiteLayout'

const VideoPage = ({ data }) => {
  let page = data.wagtail.page
  let featured = !!page.featureType

  let prev = page.previousSiblings ? page.previousSiblings[0] : null
  let next = page.nextSiblings ? page.nextSiblings[0] : null

  return (
    <SiteLayout
      page={page}
      layout={featured ? "top-nav" : "sidebar"}
      theme={featured ? "dark" : "light"}
      nav={<NavPrevNext layout={featured ? "watch" : "video"} parent={page.parent} prev={prev} next={next} />}
    >
      <Article page={page} fullUrl={`${data.wagtail.site.hostname}${page.url}`}/>
    </SiteLayout>
  )
}

export const query = graphql`
  query($url: String) {
    wagtail {
      ...SiteMetadata
      page (urlPath: $url) {
        ...PageMetadata
        ... on Video {
          transcript
          dek
          videoFile
          featureType
          imageAttribution
          backgroundText
          image {
            title
            rendition (fill: "1600x900") {
              url
            }
          }
        }
        ...PostParent
        previousSiblings {
          ...VideoSiblingData
        }
        nextSiblings {
          ...VideoSiblingData
        }
      }
    }
  }
  fragment VideoSiblingData on PageInterface {
    title
    url
    ... on Video {
      featureType
      image {
        title
        rendition(width: 300) {
          url
        }
      }
    }
    ...on Article {
      featureType
      author
    }
  }
`
export default VideoPage
