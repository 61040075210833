/* eslint-disable eqeqeq */

import React from 'react'
import { graphql, Link } from 'gatsby'

import Nav from '../atoms/Nav'
import Card from '../molecules/Card'
import HomepageHero from '../molecules/HomepageHero'
import SiteLayout from '../layouts/SiteLayout'
import './homepage.sass'


const IndexPage = ({ data }) => {
  let page = data.wagtail.page
  let featuredPosts = page.children.filter(child => child.layout == "featured")[0]
  let articles = page.children.filter(child => child.layout == "articles")[0]
  return (
    <SiteLayout layout="homepage" nav={<Nav layout="homepage"/>} page={page}>
      <div className="homepage">
        <HomepageHero data={page} className="homepage__hero" layout="homepage" />
        <div className="homepage__featured-posts theme--dark">
          <h2 className="homepage__featured-title">
            <Link className="homepage__featured-link" to={featuredPosts.url}>
              {featuredPosts.title}
            </Link>
          </h2>
          {featuredPosts.children.map(child => {
            return (
              <Card
                target={child.url}
                layout="homepage-featured"
                title={child.title}
                author={child.author}
                image={child.featureType == "watch" ? child.image : null}
                key={child.url}
                action={child.featureType}
              />
            )
          })}
        </div>
        <div className="homepage__articles">
          <h2 className="homepage__articles-title">
            <Link className="homepage__articles-link" to={articles.url}>
              {articles.title}
            </Link>
          </h2>
          <div className="homepage__articles-list">
            {articles.children.map(child => {
              return (
                <Card
                  target={child.url}
                  className="homepage__article"
                  title={child.title}
                  author={child.author}
                  key={child.url}
                />
              )
            })}
          </div>
        </div>
      </div>
    </SiteLayout>
  )
}

export const query = graphql`
  query($url: String) {
    wagtail {
      page (urlPath: $url) {
        ...PageMetadata
        ... on HomePage {
          backgroundText
          title1
          title2
          description
        }
        children {
          url
          title
          ... on PostsHomePage {
            layout
            title
            children {
              url
              title
              ... on Article {
                featureType
                author
              }
              ... on Video {
                featureType
                image {
                  title
                  rendition (fill: "800x450") {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  fragment SiteMetadata on wagtail {
    site (id: "2") {
      siteName
      hostname
    }
  }
  fragment PageMetadata on PageInterface {
    title
    url
    searchDescription
    seoTitle
    ... on Acknowledgements {
      description
      shareText
      image {
        ...OpenGraphImage
      }
    }
    ... on Article {
      dek
      shareText
      image {
        ...OpenGraphImage
      }
    }
    ... on Video {
      dek
      shareText
      image {
        ...OpenGraphImage
      }
    }
    ... on PostsHomePage {
      description
      shareText
      image {
        ...OpenGraphImage
      }
    }
    ... on HomePage {
      description
      shareText
      image {
        ...OpenGraphImage
      }
    }
  }
  fragment OpenGraphImage on ImageObjectType {
    openGraphRendition: rendition(max: "1200x1200") {
      url
    }
  }
`

export default IndexPage
