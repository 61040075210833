import React from "react"
import { Link } from "gatsby"
import Nav from '../components/atoms/Nav'
import SiteLayout from '../components/layouts/SiteLayout'

import '../styles/404.sass'

// markup
const NotFoundPage = () => {
  return (
    <SiteLayout layout="top-nav" nav={<Nav />} page={{ title: "Page not found" }}>
      <div className="page-404">
        <h1>Page not found</h1>
        <p>
          Sorry, we couldn’t find what you were looking for. 
        </p>
        <Link to="/">Go to the main page</Link>
      </div>
    </SiteLayout>
  )
}

export default NotFoundPage
